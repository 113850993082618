<template>
  <div
    class="contact-container"
    style="background-color: #073114"
    v-if="contactSingleton.show_section"
  >
    <v-container fluid>
      <v-row
        justify="center"
        style="min-height: calc(100vh - 100px)"
        align-lg="center"
      >
        <v-col cols="0" md="6" class="pa-0" order="1" order-md="0">
          <v-img
            v-if="contactSingleton.cover !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              contactSingleton.cover.path
            "
            width="100%"
            class="img-contact"
            eager
          ></v-img>
        </v-col>
        <v-col
          cols="10"
          sm="10"
          md="6"
          class="white--text"
          style="background-color: #073114"
        >
          <div class="mx-auto form-container">
            <h1 class="title-contact white--text">
              {{ contactSingleton.card.title }}
            </h1>
            <a
              :href="'tel:' + contactSingleton.card.phone"
              class="white--text d-block not-text-decoration"
              ><span class="semibold white--text">Phone: </span>
              <span class="white--text">{{
                contactSingleton.card.phone
              }}</span></a
            >
            <a
              :href="'mailto:' + contactSingleton.card.email"
              class="d-block white--text not-text-decoration"
              ><span class="semibold white--text">Email: </span>
              <span class="white--text">{{
                contactSingleton.card.email
              }}</span></a
            >
            <span class="d-block"
              ><span class="semibold white--text">Address: </span>
              <span class="white--text">{{
                contactSingleton.card.address
              }}</span></span
            >

            <h2 class="mt-6 semibold send-a-message white--text">
              Send a message
            </h2>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="pb-0 column-field">
                  <v-text-field
                    label="Name"
                    color="white"
                    hide-details="auto"
                    outlined
                    dark
                    v-model="name"
                    v-bind:rules="requiredRules"
                    v-bind:dense="!$vuetify.breakpoint.xl ? true : false"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0 column-field">
                  <v-text-field
                    label="Email"
                    type="email"
                    color="white"
                    hide-details="auto"
                    outlined
                    dark
                    v-model="email"
                    v-bind:rules="emailRules"
                    v-bind:dense="!$vuetify.breakpoint.xl ? true : false"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0 column-field">
                  <v-textarea
                    outlined
                    label="Message"
                    color="white"
                    dark
                    rows="4"
                    no-resize
                    hide-details="auto"
                    v-model="message"
                    v-bind:height="$vuetify.breakpoint.width > 2299 ? 300 : ''"
                    v-bind:rules="requiredRules"
                  ></v-textarea>
                </v-col>

                <v-col class="text-center py-0 mt-5 mt-xl-0 column-btn">
                  <v-btn
                    :ripple="false"
                    color="#008137"
                    dark
                    rounded
                    elevation="0"
                    class="btn-general btn-contact"
                    @click="validate"
                    ><span class="white--text">Send</span></v-btn
                  >
                  <v-snackbar v-model="alert" timeout="7000" :color="colorNotification" top>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon color="white">mdi-{{ iconNotification }}</v-icon>
                        <span class="white--text pl-2">
                          {{ messageNotification }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      contactSingleton: {
        cover: {
          path: "",
        },
        card: {},
      },
      valid: true,
      name: "",
      email: "",
      message: "",
      requiredRules: [(v) => !!v || "* Required"],
      emailRules: [
        (v) => !!v || "* Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      alert: false,
      messageNotification: '',
      colorNotification: '',
      iconNotification: ''
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.contactSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/contact?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data.content));
  },
  methods: {
    sendForm: function () {
      let aux = this;
      fetch(
        "https://www.lakewaycms.actstudio.xyz/api/forms/submit/Contact?token=8a2d9e7383ec51f7432915aaff81eb",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            form: {
              Name: this.name,
              Email: this.email,
              Message: this.message,
            },
          }),
        }
      )
        .then((entry) => entry.json())
        .then((entry) => console.log(entry))
        .then(function(){
          aux.colorNotification = 'rgb(0, 129, 55)';
          aux.messageNotification = 'Your message has been sent';
          aux.iconNotification = 'check-circle';
          aux.alert = true;
        })
        .catch((error) => {
          aux.colorNotification = 'red';
          aux.messageNotification = error.message;
          aux.iconNotification = 'close-circle';
          aux.alert = true;
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.sendForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.title-contact {
  margin-bottom: 25px;
  font-size: 60px;

  @media screen and(max-width: $xxl) {
    font-size: 45px;
  }

  @media screen and(max-width: $xl) {
    font-size: 36px;
    margin-top: 25px;
    margin-bottom: 13px;
  }

  @media screen and(max-width: $lg) {
    margin-top: 45px;
  }

  @media screen and(max-width: $md) {
    margin-top: 57px;
  }
}

.send-a-message {
  @media screen and(min-width: $xxl) {
    margin-top: 50px !important;
  }
  margin-bottom: 15px;
}

.img-contact {
  height: calc(100vh - 100px);

  @media screen and(max-width: $xl) {
    height: calc(100vh - 80px);
  }

  @media screen and(max-width: $md) {
    height: calc(100vh - 572px - 100px);
  }

  @media screen and(max-width: $sm) {
    height: 300px;
  }
}
.column-field {
  margin-bottom: 10px;
}
</style>